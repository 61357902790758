import { EducationProviderService } from '@/services/education-provider';
import { ApiErrorResponse } from '@/types/api';
import { ApiIndividualEducationProvider } from '@/types/education-provider';
import { UseQueryOptions } from '@tanstack/react-query';

export const UseEducationProviderQueryOptions = (
  id: string,
  options?: Omit<
    UseQueryOptions<ApiIndividualEducationProvider, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) => ({
  queryKey: [EducationProviderService.PATH, id],
  queryFn: async () => await EducationProviderService.getEducationProvider(id),
  enabled: !!id,
  ...options,
});
