import { ApiPaginationParams } from '@/types/api';
import {
  ApiIndividualInvitation,
  ApiPaginatedInvitations,
  IInvitationPayload,
} from '@/types/invitation';
import qs from 'query-string';
import { ApiService } from './api';

export class InvitationService extends ApiService {
  static PATH = '/invitations';

  static async getInvitations(
    pagination: ApiPaginationParams = {},
  ): Promise<ApiPaginatedInvitations> {
    const queryString = qs.stringify(pagination);
    return ApiService.get(`${InvitationService.PATH}?${queryString}`);
  }

  static async postInvitation(
    payload: IInvitationPayload,
  ): Promise<ApiIndividualInvitation> {
    return ApiService.post(InvitationService.PATH, payload);
  }

  static async deleteInvitation(invitationId: string): Promise<void> {
    return ApiService.delete(`${InvitationService.PATH}/${invitationId}`);
  }
}
