import { EXTERNAL_ROUTES } from '@/constants';
import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { CheckCircle, ChevronLeft } from '@mui/icons-material';
import { Link } from '@tanstack/react-router';
import {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useContractSignatureTransientState } from '../hooks/useContractSignatureTransientState';
import { FlowModes } from '../types';

type OnboardingStatusWidgetProps = ComponentProps<'div'>;

export function OnboardingStatusWidget(props: OnboardingStatusWidgetProps) {
  const {
    educationProvider,
    currentUser,
    isLoading: isLoadingUser,
  } = useCurrentUserContext() ?? {};
  const {
    contractInfo: transientContractInfo,
    isLoading: isLoadingTransientContractInfo,
  } = useContractSignatureTransientState();

  const isLoading = isLoadingUser || isLoadingTransientContractInfo;

  const userIsLegalRepresentativeContact = !!(
    currentUser?.email &&
    educationProvider?.info?.contractRepresentativeContact &&
    educationProvider?.info?.contacts?.find(
      contact =>
        contact._id ===
          educationProvider?.info?.contractRepresentativeContact &&
        contact.email === currentUser.email,
    )
  );

  const stepsAndStatus = useMemo(
    () =>
      [
        {
          title: 'Sign up to Mydra',
          isCompleted: true,
        },
        {
          title: 'Fill the Onboarding Steps',
          isCompleted:
            !!educationProvider?.onboardingState?.hasLegalInformation &&
            !!educationProvider?.onboardingState?.hasLegalRepresentativeContact,
          incompleteLink: EXTERNAL_ROUTES.EDUCATION_PROVIDER_ONBOARDING,
        },
        {
          title: 'Connect to Stripe',
          isCompleted:
            !!educationProvider?.onboardingState?.hasActivePaymentProvider,
          incompleteLink: `/stripe?mode=${FlowModes.CONNECT_ACCOUNT}&id=${educationProvider?._id}`,
          completeLink: '/settings',
        },
        {
          title: 'Sign the agreement',
          isCompleted:
            !!transientContractInfo?.envelopeId ||
            !!educationProvider?.onboardingState?.hasActiveAgreement,
          incompleteLink: '/contract',
          completeLink: '/settings',
          disabled: !userIsLegalRepresentativeContact,
        },
      ].map((item, index, items) => {
        const prevIndex = Math.max(index, 0);
        return {
          ...item,
          disabled:
            item.disabled ||
            items.slice(0, prevIndex).some(item => !item.isCompleted),
        };
      }),
    [
      educationProvider?.onboardingState,
      educationProvider?._id,
      transientContractInfo,
      userIsLegalRepresentativeContact,
    ],
  );

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(stepsAndStatus.some(item => !item.isCompleted));
  }, [stepsAndStatus]);

  if (
    isLoading ||
    !educationProvider ||
    !educationProvider.onboardingState ||
    stepsAndStatus.every(item => item.isCompleted)
  ) {
    return null;
  }

  return (
    <div
      {...props}
      className={`overflow-hidden z-30 animate-appear ease-in-out text-sm w-[214px] text-mydra-white ${props.className ?? ''}`}
    >
      <div className="overflow-hidden rounded">
        <div
          className={`transition-transform overflow-hidden bg-text ${isOpen ? 'translate-y-0' : 'translate-y-full'}`}
        >
          <header className="px-3 py-2 bg-titles/40">
            <h4 className="leading-6">Getting Started Guide</h4>
            <p className="leading-3 text-xxs text-mydra-mouse">
              Learn more about Mydra onboarding in simple{' '}
              {stepsAndStatus.length} steps
            </p>
          </header>
          <ul className="px-3">
            {stepsAndStatus.map(
              (
                { title, isCompleted, incompleteLink, completeLink, disabled },
                index,
              ) => (
                <OnboardingStatusWidgetItem
                  key={title}
                  step={index + 1}
                  title={title}
                  isCompleted={isCompleted}
                  disabled={disabled}
                  href={isCompleted ? completeLink : incompleteLink}
                />
              ),
            )}
          </ul>
        </div>
      </div>
      <button
        className="flex overflow-hidden items-center px-3 py-2 mt-2 mr-auto text-xs rounded-lg bg-text"
        onClick={() => {
          setOpen(v => !v);
        }}
      >
        <span className="leading-6">Getting Started Guide</span>
        <ChevronLeft
          className={`transform transition-transform ${isOpen ? '-rotate-90' : 'rotate-90'}`}
        />
      </button>
    </div>
  );
}

type OnboardingStatusWidgetItemProps = {
  title: string;
  isCompleted: boolean;
  step: number;
  href?: string;
  disabled?: boolean;
} & ComponentProps<'li'>;

export function OnboardingStatusWidgetItem({
  title,
  isCompleted,
  step,
  href,
  disabled,
  ...props
}: OnboardingStatusWidgetItemProps) {
  const WrapperComponent =
    href && !disabled
      ? (props: PropsWithChildren) => <Link {...props} to={href} />
      : 'span';

  return (
    <li
      {...props}
      className={`border-b border-mydra-mouse last-of-type:border-transparent py-4 px-1/2 text-xs text-white leading-6 ${props.className ?? ''}`}
    >
      <WrapperComponent className="flex gap-3 items-center">
        {isCompleted ? (
          <CheckCircle className="!text-[20px]" />
        ) : (
          <div className="flex justify-center items-center w-5 h-5 font-sans font-bold rounded-full border border-white shrink-0 aspect-square text-bold">
            {step}
          </div>
        )}
        <h5>{title}</h5>
      </WrapperComponent>
    </li>
  );
}
