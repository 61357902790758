import {
  FormControl,
  FormControlProps,
  Input,
  InputInputSlotPropsOverrides,
  InputOwnerState,
  SlotComponentProps,
} from '@mui/base';
import { forwardRef } from 'react';

type TextFieldProps = FormControlProps<'input'> & {
  errorMessage?: string;
  helperText?: string;
  label?: string;
  slotProps?: FormControlProps['slotProps'] & {
    input?: SlotComponentProps<
      'input',
      InputInputSlotPropsOverrides,
      InputOwnerState
    > & {
      startAdornment?: React.ReactNode;
      endAdornment?: React.ReactNode;
    };
    label?: SlotComponentProps<'label', object, InputOwnerState>;
  };
};

export const TextField = forwardRef(function TextField(
  props: TextFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { id, type, slotProps, label, placeholder, error, helperText, name } =
    props;
  const {
    errorMessage,
    id: _id,
    name: _name,
    ref: _ref,
    ...propsWithoutErrorMessage
  } = props;
  const { endAdornment, startAdornment, ...originalInputSlotProps } =
    slotProps?.input ?? {};

  return (
    <FormControl
      error={error || !!errorMessage}
      className="flex flex-col gap-2"
      {...propsWithoutErrorMessage}
    >
      {({ error }) => (
        <>
          <label
            {...slotProps?.label}
            htmlFor={id}
            className={`text-sm text-mydra-black font-medium ${
              typeof slotProps?.label === 'object'
                ? slotProps.label.className
                : ''
            }`}
          >
            {label}
          </label>
          <div className="flex w-full border rounded-xl px-3 py-2 gap-1 focus-within:ring-2 focus-within:ring-mydra-black">
            {slotProps?.input?.startAdornment && (
              <div className="flex items-center shrink-0">
                {slotProps.input.startAdornment}
              </div>
            )}
            <Input
              placeholder={placeholder}
              id={id}
              name={name}
              type={type}
              className="flex-1"
              slotProps={{
                input: {
                  ref,
                  autoComplete: 'current-password',
                  className: `appearance-none focus:ring-0 focus:outline-none h-full w-full`,
                  ...originalInputSlotProps,
                },
              }}
            />
            {slotProps?.input?.endAdornment && (
              <div className="flex items-center shrink-0">
                {slotProps.input.endAdornment}
              </div>
            )}
          </div>
          {error && errorMessage ? (
            <p className="text-sm text-red-500">{errorMessage}</p>
          ) : helperText ? (
            <p className="text-sm text-text">{helperText}</p>
          ) : (
            <></>
          )}
        </>
      )}
    </FormControl>
  );
});
