import { ListApplicationPage } from '@/modules/applications/pages/ListApplicationsPage';
import { ApplicationsSearchParams } from '@/modules/applications/types';
import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ApplicationStatus, ApplicationTerm } from '@/types/application';
import { createFileRoute } from '@tanstack/react-router';
import * as yup from 'yup';

export const Route = createFileRoute('/_admin/applications/')({
  validateSearch: (
    search: Record<string, unknown>,
  ): ApplicationsSearchParams => {
    return {
      page: yup.number().integer().min(1).optional().validateSync(search.page),
      limit: yup
        .number()
        .integer()
        .min(1)
        .optional()
        .validateSync(search.limit),
      status: yup
        .array()
        .of(yup.string().oneOf(Object.values(ApplicationStatus)).required())
        .optional()
        .validateSync(
          search.status
            ? Array.isArray(search.status)
              ? search.status
              : [search.status]
            : [],
        ),
      applicationTerm: yup
        .string()
        .oneOf(Object.values(ApplicationTerm))
        .optional()
        .validateSync(search.applicationTerm),
      hasLearningBudget: yup
        .boolean()
        .optional()
        .validateSync(search.hasLearningBudget),
    };
  },
  component: () => {
    return (
      <AdminLayout>
        <ListApplicationPage />
      </AdminLayout>
    );
  },
});
