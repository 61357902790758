import { Button } from '@/modules/common/components/Button';
import { Modal } from '@/modules/common/components/Modal';
import { ApplicationValidationType } from '@/types/application';
import { UserRole } from '@/types/user';
import { Check } from '@mui/icons-material';
import { ReviewModalProps } from './ReviewModal.types';

type EducationProviderReviewModalProps = Omit<
  ReviewModalProps,
  'initialReview'
> & {
  validationsToReview: ApplicationValidationType[];
};

export function EducationProviderReviewModal({
  applicationsToReview,
  validationsToReview,
  userRoles,
  onClose,
  ...props
}: EducationProviderReviewModalProps) {
  const [application] = applicationsToReview ?? [];
  const [validationToReview] = validationsToReview ?? [];

  if (!application || !validationToReview) {
    return null;
  }

  const {
    user: {
      firstName,
      lastName,
      email,
      profile: { linkedInUrl, skills, currentRole } = {},
    },
    course: { name: courseName, cohorts: courseCohorts } = {},
    cohort: cohortId,
  } = application;

  const cohort = courseCohorts?.find(cohort => cohort._id === cohortId);

  if (
    !application ||
    !application.user ||
    !validationToReview ||
    !userRoles.includes(UserRole.EducationProvider) ||
    !cohort
  ) {
    throw new Error('Invalid application or validation');
  }

  const cohortStartDate = cohort.startDate
    ? new Date(cohort.startDate).toLocaleDateString()
    : 'TBD';

  const userName = [firstName, lastName].filter(Boolean).join(' ');

  const modalTitle =
    validationToReview === ApplicationValidationType.Skills
      ? "Please verify the user's skills for this application"
      : validationToReview === ApplicationValidationType.Capacity
        ? 'Please verify user enrollment capacity'
        : "Please verify the user's eligibility for admission";

  const modalDescription =
    validationToReview === ApplicationValidationType.Skills
      ? `By clicking 'Approve' will confirm the user's eligibility for the
          course automatically. If rejected, the user will be notified via email
          with additional information.`
      : validationToReview === ApplicationValidationType.Capacity
        ? `By clicking 'Approve' will confirm the user's enrollment in the ${courseName} starting ${cohortStartDate}. If rejected, the user will be notified via email with additional information.`
        : `By clicking 'Approve' will confirm the user's eligibility for admission. If rejected, the user will be notified via email with additional information.`;

  return (
    <Modal
      showCloseButton
      onClose={() => onClose()}
      {...props}
      title={modalTitle}
      className="max-w-2xl"
    >
      <div className="flex flex-col gap-4">
        <p className="text-lg">{modalDescription}</p>
        <p className="text-lg font-medium">Student's overview</p>
        <div className="flex flex-col gap-3">
          <div className="div">
            <p className="font-medium">{userName}</p>
            <p>{email}</p>
          </div>
          {[
            ApplicationValidationType.Skills,
            ApplicationValidationType.AdmissionProcess,
          ].includes(validationToReview) && (
            <div>
              <p className="font-medium">LinkedIn</p>
              <p>
                <a href={linkedInUrl} target="_blank" rel="noreferrer">
                  {linkedInUrl}
                </a>
              </p>
            </div>
          )}
          {[
            ApplicationValidationType.Capacity,
            ApplicationValidationType.AdmissionProcess,
          ].includes(validationToReview) && (
            <div>
              <p className="font-medium">Job role</p>
              <p>{currentRole}</p>
            </div>
          )}
          <div>
            <p className="font-medium">Skills</p>
            <p>{skills?.join(', ') || '(none)'}</p>
          </div>
        </div>
        <div className="flex gap-3 justify-end">
          <Button
            onClick={() => {
              onClose({
                [validationToReview]: false,
              });
            }}
          >
            Reject
          </Button>
          <Button
            primary
            onClick={() => {
              onClose({
                [validationToReview]: true,
              });
            }}
          >
            <div className="flex gap-2 items-center">
              Approve <Check />
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
