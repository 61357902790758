import { USER_ROLES } from '@/constants';
import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Dropdown, DropdownProps, Menu, MenuButton } from '@mui/base';
import {
  ExpandMore,
  Logout,
  ManageAccountsOutlined,
} from '@mui/icons-material';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';
import { ImageWithFallback } from '../../ImageWithFallback';
import { MenuItem } from '../../MenuItem';

type UserMenuProps = Omit<DropdownProps, 'children'> &
  React.HTMLAttributes<HTMLDivElement>;

export function UserMenu(props: UserMenuProps) {
  const [isOpen, setOpen] = React.useState(false);
  const navigate = useNavigate();

  // TODO: tweak the positio the drop-down appears to match the figma
  // TODO: refactor to use the DropDown component

  const {
    currentUser: { firstName = '', lastName = '' } = {},
    educationProvider,
  } = useCurrentUserContext() ?? {};
  const userName = [firstName, lastName].filter(Boolean).join(' ');
  const userContext = useCurrentUserContext();

  return (
    <Dropdown
      {...props}
      open={isOpen}
      onOpenChange={() => {
        setOpen(prevOpen => !prevOpen);
      }}
    >
      <MenuButton className="flex items-center text-text">
        <ImageWithFallback
          fallbackSrc="/images/user-profile.png"
          src={educationProvider?.logoUrl}
          alt="User profile"
          className="w-10 h-10 rounded-full"
        />
        <span className="ml-2 font-medium">{userName}</span>
        <ExpandMore />
      </MenuButton>
      <Menu
        className={`bg-white border z-50 rounded-lg shadow-user-menu ${isOpen ? 'min-w-56' : ''}`}
      >
        {userContext?.currentUser.roles?.some(
          role =>
            role === USER_ROLES.EMPLOYER ||
            role === USER_ROLES.EDUCATION_PROVIDER,
        ) && (
          <MenuItem
            className="border-b last-of-type:border-none"
            icon={<ManageAccountsOutlined />}
            onClick={() =>
              navigate({
                to: '/settings',
              })
            }
          >
            Organization settings
          </MenuItem>
        )}
        <MenuItem
          className="border-b last-of-type:border-none"
          icon={<Logout />}
          onClick={() => {
            window.location.href = '/logout';
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
