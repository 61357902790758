import { InvitationService } from '@/services/invitation';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

export const useInvitationDeletion = (
  options: Omit<
    UseMutationOptions<void, Error, string>,
    'mutationFn' | 'queryKey'
  > = {},
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [InvitationService.PATH],
    mutationFn: (invitationId: string) =>
      InvitationService.deleteInvitation(invitationId),
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: [InvitationService.PATH],
        exact: false,
      });

      options?.onSuccess?.(...args);
    },
    ...options,
  });
};
