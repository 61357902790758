import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { useNotificationContext } from '@/modules/common/hooks/useNotificationContext';
import { NotificationStyle } from '@/modules/common/providers/NotificationProvider.types';
import { ContractKind, IContractStatus } from '@/types/contract';
import { DocumentType } from '@/types/document';
import { useEffect } from 'react';
import { useContractSignatureTransientState } from '../hooks/useContractSignatureTransientState';
import { useContracts } from '../hooks/useContracts';
import { useDocument } from '../hooks/useDocument';
import { useDocuments } from '../hooks/useDocuments';

type ContractInfoBannerProps = React.HTMLAttributes<HTMLDivElement>;

export function ContractInfoBanner(props: ContractInfoBannerProps) {
  const { educationProvider, isLoading: isLoadingUser } =
    useCurrentUserContext() ?? {};
  const { onboardingState } = educationProvider ?? {};
  const { showNotification } = useNotificationContext();

  const {
    contractInfo: transientContractInfo,
    isLoading: isLoadingTransientContractInfo,
    clearContractTransientState,
  } = useContractSignatureTransientState();

  const {
    data: { data: [lastSignedContract] = [] } = {},
    isLoading: isLoadingContracts,
  } = useContracts(
    {
      educationProviderId: educationProvider?._id,
      status: [IContractStatus.Signed],
      contractKind: ContractKind.EducationProviderEPFinancingContract,
    },
    undefined,
    {
      updatedAt: 'desc',
    },
    {
      enabled: onboardingState?.hasActiveAgreement,
    },
  );

  const {
    isLoading: isLoadingContractDocuments,
    data: { data: [lastContractDocument] = [] } = {},
  } = useDocuments(
    {
      type: DocumentType.Contract,
    },
    undefined,
    {
      updatedAt: 'desc',
    },
    {
      enabled: !!(
        onboardingState?.hasActiveAgreement && lastSignedContract?._id
      ),
    },
  );

  const {
    refetch: fetchContractDocumentWithDownloadLink,
    isRefetching: isLoadingContractDocumentWithDownloadLink,
  } = useDocument(lastContractDocument?._id, {
    enabled: false,
  });

  const hasSignedTheContract = lastSignedContract?._id !== undefined;
  const shouldSignAContract =
    onboardingState &&
    onboardingState.hasLegalInformation &&
    onboardingState.hasLegalRepresentativeContact &&
    onboardingState.hasActiveAgreement &&
    !onboardingState.hasActiveAgreement;

  const isLoading =
    isLoadingTransientContractInfo ||
    isLoadingUser ||
    isLoadingContracts ||
    isLoadingContractDocuments ||
    isLoadingContractDocumentWithDownloadLink;

  async function downloadContractDocument() {
    if (
      !onboardingState ||
      !onboardingState.hasActiveAgreement ||
      !lastSignedContract?._id ||
      !lastContractDocument?._id
    ) {
      return;
    }

    try {
      const { data: { data: lastContractDocumentWithDownloadLink } = {} } =
        await fetchContractDocumentWithDownloadLink();

      if (!lastContractDocumentWithDownloadLink?.temporaryUrl) {
        throw new Error(
          'Document does not exists or does not have a download link.',
        );
      }

      window.open(
        lastContractDocumentWithDownloadLink.temporaryUrl,
        '_blank',
        'noopener,noreferrer',
      );
    } catch {
      showNotification(
        'Failed to download contract document. If the error persists, please contact our support.',
        NotificationStyle.ERROR,
      );
    }
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (hasSignedTheContract) {
      clearContractTransientState();
    }
  }, [hasSignedTheContract, clearContractTransientState, isLoading]);

  return hasSignedTheContract && !transientContractInfo?.envelopeId ? (
    <div
      {...props}
      className={`flex flex-col @md:flex-row @md:items-center @md:justify-between gap-3 border rounded-xl px-3 py-6 @md:px-4 overflow-hidden bg-gray-background ${props.className ?? ''} `}
    >
      <div className="flex flex-col gap-3">
        <div className="flex gap-1 items-center">
          <h3 className="text-sm @md:text-base font-medium @md:font-semibold">
            Contract details
          </h3>
        </div>
        <p className="text-xs @md:text-sm @md:font-medium">
          This document details the terms and conditions of your partnership
          agreement.
        </p>
      </div>
      <Button
        isLoading={isLoadingContractDocuments}
        className="@md:ml-auto"
        disabled={isLoading}
        small
        onClick={downloadContractDocument}
      >
        {shouldSignAContract ? 'Sign agreement' : 'View contract'}
      </Button>
    </div>
  ) : null;
}
