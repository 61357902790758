import * as Sentry from '@sentry/react';
import { ValidationError } from 'yup';
import { ApiError } from './services/api';

Sentry.init({
  dsn: 'https://e67e97613b2b78a04898874a613f0533@o370725.ingest.us.sentry.io/4508688854614016',
  integrations: [],
  ignoreErrors: [
    'AbortError',
    'AbortSignal',
    'AbortController',
    'AbortController',
    'ValidationError',
    'SearchParamError',
    'PathParamError',
    'ApiError',
  ],
  beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
    if (
      [ValidationError, SearchParamError, PathParamError, ApiError].some(
        error => hint.originalException instanceof error,
      )
    ) {
      return null;
    }
    return event;
  },
  environment: import.meta.env.VITE_APP_ENV ?? 'local',
});

import {
  PathParamError,
  RouterProvider,
  SearchParamError,
  createRouter,
} from '@tanstack/react-router';

import ReactDOM from 'react-dom/client';

import './main.css';

// Import the generated route tree
import { ClassNameConfigurator } from '@mui/base';
import { routeTree } from './routeTree.gen';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { NotificationProvider } from './modules/common/providers/NotificationProvider';

const queryCache = new QueryCache({
  onError: async error => {
    if (error instanceof ApiError && error.statusCode >= 500) {
      Sentry.captureException(error);
    }

    if (error instanceof ApiError && error.statusCode === 401) {
      const resolvedRedirect = router.resolveRedirect({
        to: '/login',
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirectTo: location.pathname + location.search,
        },
      });
      window.location.href = resolvedRedirect.href;
    }
  },
});

const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (
          error instanceof ApiError &&
          Number.isInteger(error.statusCode) &&
          error.statusCode >= 400 &&
          error.statusCode < 500
        ) {
          return false;
        }
        return failureCount < 3;
      },
    },
    mutations: {
      retry: (failureCount, error) => {
        if (error instanceof ApiError && error.statusCode < 500) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

// Create a new router instance
const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById('app')!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <ClassNameConfigurator disableDefaultClasses>
      <QueryClientProvider client={queryClient}>
        <NotificationProvider>
          <RouterProvider router={router} />
        </NotificationProvider>
      </QueryClientProvider>
    </ClassNameConfigurator>,
  );
}
