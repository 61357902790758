import { useGetEducationProvider } from '@/modules/common/hooks/useEducationProvider';
import { useGetEmployer } from '@/modules/common/hooks/useEmployer';
import { getUseCurrentUserOptions } from '@/modules/common/hooks/useUser';
import { ICompany } from '@/types/company';
import { IEducationProviderWithoutUserObjects } from '@/types/education-provider';
import { IEmployer } from '@/types/employer';
import { ITaxBenefitsProvider } from '@/types/tax-benefits-provider';
import { IUser, IUserWithEducationProvider, UserInclude } from '@/types/user';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createContext } from 'react';

type CurrentUserContextProviderProps = {
  currentUser: IUser | IUserWithEducationProvider;
  educationProvider?: IEducationProviderWithoutUserObjects;
  employer?: IEmployer;
  taxBenefitsProvider?: ITaxBenefitsProvider;
  isLoading?: boolean;
};

export const CurrentUserContext =
  createContext<CurrentUserContextProviderProps | null>(null);

export const CurrentUserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    data: { data: currentUser },
    isLoading: isLoadingUser,
  } = useSuspenseQuery(
    getUseCurrentUserOptions([
      UserInclude.Company,
      UserInclude.TaxBenefitProviders,
    ]),
  );

  const { data: { data: employer } = {} } = useGetEmployer(
    (currentUser?.company as ICompany)?._id ?? '',
  );

  const { data: { data: educationProvider } = {} } = useGetEducationProvider(
    currentUser?.educationProvider?.toString() ?? '',
  );

  const [taxBenefitsProvider] = currentUser?.taxBenefitProviders ?? [];

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        ...(employer && { employer }),
        ...(taxBenefitsProvider && { taxBenefitsProvider }),
        ...(educationProvider && {
          educationProvider:
            educationProvider as IEducationProviderWithoutUserObjects,
        }),
        isLoading: isLoadingUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
