import { Tag } from '@/modules/common/components/Tag';
import { Tooltip } from '@/modules/common/components/Tooltip';
import {
  ApplicationStatus as ApplicationStatusTypes,
  IApplicationValidation,
} from '@/types/application';
import { ClickAwayListener } from '@mui/base';
import { InfoOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useApplicationValidationStatusText } from '../../hooks/useApplicationValidationStatusText';

type ApplicationStatusProps = {
  status: ApplicationStatusTypes;
  validations?: IApplicationValidation[];
  sequence: number;
};

const statusInfo: {
  [key in ApplicationStatusTypes]: {
    bgColor: string;
    label: string;
    tooltip?: string;
  };
} = {
  [ApplicationStatusTypes.Applied]: {
    bgColor: 'bg-mydra-violet',
    label: 'Applied',
  },
  [ApplicationStatusTypes.PendingValidation]: {
    bgColor: 'bg-mydra-yellow',
    label: 'Pending validation',
  },
  [ApplicationStatusTypes.Approved]: {
    bgColor: 'bg-mydra-blue',
    label: 'Approved',
  },
  [ApplicationStatusTypes.Cancelled]: {
    bgColor: 'bg-mydra-medium-gray',
    label: 'Cancelled',
  },
  [ApplicationStatusTypes.Rejected]: {
    bgColor: 'bg-mydra-light-pink',
    label: 'Rejected',
  },
  [ApplicationStatusTypes.MissingScoringData]: {
    bgColor: 'bg-mydra-light-orange',
    label: 'Missing scoring data',
  },
  [ApplicationStatusTypes.PendingContract]: {
    bgColor: 'bg-mydra-medium-purple',
    label: 'Pending contract',
    tooltip: 'The user must sign their contract',
  },
  [ApplicationStatusTypes.ContractSigned]: {
    bgColor: 'bg-mydra-light-blue',
    label: 'Contract signed',
  },
  [ApplicationStatusTypes.Complete]: {
    bgColor: 'bg-mydra-medium-green',
    label: 'Complete',
  },
};

export function ApplicationStatus({
  status,
  validations,
  sequence,
}: ApplicationStatusProps) {

  const { getStatusText } = useApplicationValidationStatusText();
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const tooltip =
    status === ApplicationStatusTypes.PendingValidation &&
    Array.isArray(validations) &&
    validations?.length > 0
      ? getStatusText(validations)
      : statusInfo[status]?.tooltip;

  return (
    <Tag
      className={`w-fit whitespace-nowrap	${statusInfo[status]?.bgColor ?? ''}`}
      text={
        <div className="flex gap-1 text-mydra-black" ref={setTooltipRef}>
          {sequence}. {statusInfo[status]?.label ?? ''}
          {tooltip && (
            <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
              <div className="w-3 h-3 text-[#5F6368]">
                <InfoOutlined
                  fontSize="inherit"
                  className="cursor-pointer"
                  onClick={() => setIsTooltipOpen(true)}
                />
                <Tooltip open={isTooltipOpen} reference={tooltipRef}>
                  {tooltip}
                </Tooltip>
              </div>
            </ClickAwayListener>
          )}
        </div>
      }
    />
  );
}
