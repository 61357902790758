import { LogoutPage } from '@/modules/auth/pages/LogoutPage';
import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { createFileRoute } from '@tanstack/react-router';

export type LogoutPageSearchParams = {
  redirectTo?: string;
};

const useSelfAuthUI = /true/i.test(import.meta.env.VITE_USE_SELF_AUTH_UI ?? '');

export const Route = createFileRoute('/logout')({
  beforeLoad: ({ search }) => {
    if (!useSelfAuthUI) {
      const { redirectTo = '/' } = search as LogoutPageSearchParams;

      const authRedirect = `${import.meta.env.VITE_EP_DASHBOARD_URL}${redirectTo}`;

      const url = new URL(import.meta.env.VITE_MYDRA_AUTH_URL + '/auth/logout');

      url.searchParams.append('redirectTo', authRedirect);

      window.location.href = url.toString();
    }
  },
  component: () => <LogoutPage />,
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 500 }} reset={() => null} />;
  },
});
