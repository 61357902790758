import { LoginPage } from '@/modules/auth/pages/LoginPage';
import { ErrorMessage } from '@/modules/common/components/ErrorMessage';
import { createFileRoute } from '@tanstack/react-router';

export type LoginPageSearchParams = {
  redirectTo?: string;
  token?: string;
};

const useSelfAuthUI = /true/i.test(import.meta.env.VITE_USE_SELF_AUTH_UI ?? '');

export const Route = createFileRoute('/login')({
  beforeLoad: ({ search }) => {
    if (!useSelfAuthUI) {
      const { redirectTo = '/' } = search as LoginPageSearchParams;

      const authRedirect = `${import.meta.env.VITE_EP_DASHBOARD_URL}${redirectTo}`;

      const url = new URL(import.meta.env.VITE_MYDRA_AUTH_URL + '/login');

      url.searchParams.append('redirectTo', authRedirect);

      window.location.href = url.toString();
    }
  },
  component: () => <LoginPage />,
  validateSearch: (search: Record<string, unknown>): LoginPageSearchParams => {
    return search.redirectTo
      ? {
          redirectTo: search.redirectTo.toString().startsWith('/')
            ? search.redirectTo.toString()
            : '',
          token: search.token?.toString() ?? '',
        }
      : {};
  },
  errorComponent: () => {
    return <ErrorMessage error={{ statusCode: 500 }} reset={() => null} />;
  },
});
