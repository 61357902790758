import { ApiPaginationParams } from '@/types/api';
import {
  ApiIndividualApplication,
  ApiPaginatedApplications,
  ApplicationStatus,
  ApplicationValidationStatus,
  ApplicationValidationType,
  IApplication,
} from '@/types/application';
import qs from 'query-string';
import { ApiService } from './api';

export type ApplicationFilters = {
  status?: ApplicationStatus | ApplicationStatus[];
};

export class ApplicationService extends ApiService {
  static PATH = '/applications';

  static async getApplications(
    filters: ApplicationFilters = {},
    pagination: ApiPaginationParams = {},
    include?: string[],
  ): Promise<ApiPaginatedApplications> {
    const queryString = qs.stringify(
      {
        ...filters,
        ...pagination,
        include,
      },
      { arrayFormat: 'none' },
    );
    return ApiService.get(`${this.PATH}?${queryString}`, {
      headers: {
        'accept-language': 'en',
      },
    });
  }

  static async getApplication(id: string): Promise<ApiIndividualApplication> {
    return ApiService.get(`${this.PATH}/${id}`, {
      headers: {
        'accept-language': 'en',
      },
    });
  }

  static async patchApplication(
    id: string,
    data: Partial<IApplication>,
  ): Promise<ApiIndividualApplication> {
    return ApiService.patch(`${this.PATH}/${id}`, data);
  }

  static async reviewApplication(
    id: string,
    data: {
      isApproved: boolean;
      validationType: ApplicationValidationType;
      comments?: string;
    },
  ): Promise<ApiIndividualApplication> {
    return ApiService.review(
      `${this.PATH}/${id}/validations/${data.validationType}/reviews`,
      {
        status: data.isApproved
          ? ApplicationValidationStatus.Approved
          : ApplicationValidationStatus.Rejected,
        comments: data.comments,
      },
    );
  }
}
