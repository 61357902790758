export const API_URL = import.meta.env.VITE_API_URL;
export const MARKETPLACE_URL = import.meta.env.VITE_MARKETPLACE_URL;
export const APP_ENV = import.meta.env.VITE_APP_ENV;

export const EXTERNAL_ROUTES = {
  LANDING_PAGE_URL: MARKETPLACE_URL + '/:lang/landing-pages/:id',
  EDUCATION_PROVIDER_ONBOARDING:
    MARKETPLACE_URL + '/onboarding/education-provider',
  MARKETPLACE_COURSE_URL: MARKETPLACE_URL + '/courses/:id',
};

export const USER_ROLES = {
  ADMIN: 'admin',
  EDUCATION_PROVIDER: 'educationprovider',
  USER: 'student',
  EMPLOYER: 'employer',
  TAX_BENEFITS_PROVIDER: 'taxbenefitsprovider',
};

export const DEFAULT_COURSE_CLASS_TYPES = ['live', 'pre-recorded'];
export const DEFAULT_COURSE_FORMATS = ['self-paced', 'full-time', 'part-time'];
export const DEFAULT_COURSE_LEVELS = ['beginner', 'intermediate', 'advanced'];
