import { AdminLayout } from '@/modules/common/layouts/AdminLayout';
import { ListCoursesPage } from '@/modules/courses/pages/ListCoursesPage';
import { createFileRoute } from '@tanstack/react-router';

export type CoursesSearchParams = {
  page?: number;
  category?: string;
  published?: boolean;
  term?: string;
};

export const Route = createFileRoute('/_admin/courses/')({
  validateSearch: (rawSearch: Record<string, unknown>): CoursesSearchParams => {
    return {
      ...(Number.isInteger(rawSearch.page) && {
        page: +(rawSearch.page as string),
      }),
      ...(!!rawSearch.category && {
        category: rawSearch.category as string,
      }),
      ...(rawSearch.published !== undefined && {
        published: rawSearch.published as boolean,
      }),
      ...(!!rawSearch.term && {
        term: rawSearch.term as string,
      }),
    };
  },
  component: () => {
    return (
      <AdminLayout>
        <ListCoursesPage />
      </AdminLayout>
    );
  },
});
