import { ApiIndividualResponse, ApiPaginatedResponse } from './api';
import { IDocumentReference } from './document-reference';
import { ITeam } from './team';
import { IUser } from './user';

export type ApiIndividualInvitation = ApiIndividualResponse<IInvitation>;
export type ApiPaginatedInvitations = ApiPaginatedResponse<IInvitation>;

export enum InviteType {
  User = 'user',
  EducationProvider = 'education_provider',
  EducationProviderLegalRepresentative = 'education_provider_legal_representative',
}

export interface IInvitation {
  _id?: string;
  email: string;
  partner: IDocumentReference;
  role?: string;
  team?: ITeam | string;
  token?: string;
  expiresAt: Date;
  invitedBy: IUser | string;
  inviteType: InviteType;
  usedAt?: Date;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export type IInvitationPayload = {
  email: string;
  role: string;
  team?: string;
  inviteType: InviteType;
};

export interface IInvitationGetFilters {
  email?: string;
  inviteType?: InviteType;
  invitedBy?: string;
}
