import { EXTERNAL_ROUTES } from '@/constants';
import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { Button } from '@/modules/common/components/Button';
import { Modal, ModalProps } from '@/modules/common/components/Modal';
import { useInvitationDeletion } from '@/modules/employees/hooks/useInvitationDeletion';
import { useGetInvitations } from '@/modules/employees/hooks/useInvitations';
import { InviteType } from '@/types/invitation';
import { useCallback, useState } from 'react';

type FinishOnboardingBannerProps = React.HTMLAttributes<HTMLDivElement>;

export function FinishOnboardingBanner(props: FinishOnboardingBannerProps) {
  const {
    educationProvider,
    currentUser,
    isLoading: isLoadingUser,
  } = useCurrentUserContext() ?? {};
  const { onboardingState } = educationProvider ?? {};
  const { mutateAsync: deleteInvitation } = useInvitationDeletion();
  const [isDeleteInvitationModalOpen, setIsDeleteInvitationModalOpen] =
    useState(false);

  const { data: { data: invitations } = {}, isLoading: isLoadingInvitations } =
    useGetInvitations(
      {
        inviteType: InviteType.EducationProviderLegalRepresentative,
        invitedBy: currentUser?._id,
      },
      undefined,
      {
        enabled: !!currentUser?._id,
      },
    );

  const shouldFinishOnboarding =
    onboardingState &&
    !onboardingState.hasLegalRepresentativeContact &&
    !onboardingState.hasLegalInformation;

  const legalRepresentativeInvitation = (invitations ?? [])?.find(
    invitation =>
      invitation.inviteType === InviteType.EducationProviderLegalRepresentative,
  );

  const isLoading = isLoadingUser || isLoadingInvitations;

  const redirectToMarketplaceOnboarding = useCallback(() => {
    window.location.assign(EXTERNAL_ROUTES.EDUCATION_PROVIDER_ONBOARDING);
  }, []);

  const handleDeleteInvitationModalChoice = useCallback(
    async (shouldDelete: boolean) => {
      if (shouldDelete && legalRepresentativeInvitation) {
        await deleteInvitation(legalRepresentativeInvitation._id!);
      }
      setIsDeleteInvitationModalOpen(false);
    },
    [deleteInvitation, legalRepresentativeInvitation],
  );

  if (isLoading) {
    return null;
  }

  return shouldFinishOnboarding ? (
    <div
      {...props}
      className={`flex flex-col @md:flex-row @md:items-center @md:justify-between gap-3 border rounded-xl px-3 py-6 @md:px-4 overflow-hidden bg-gray-background ${props.className ?? ''} `}
    >
      <div className="flex flex-col gap-3">
        <div className="flex gap-1 items-center">
          <h3 className="text-sm @md:text-base font-medium @md:font-semibold">
            Some Organization Information Is Missing!
          </h3>
        </div>
        <p className="text-xs @md:text-sm @md:font-medium">
          Are you the Authorized Legal Representative of{' '}
          {educationProvider?.name}? Please update finish the onboarding of your
          organization. If not, please invite the authorized representative to
          provide the necessary information.
        </p>
      </div>
      <div className="flex flex-col gap-4 shrink-0">
        <Button
          primary
          isLoading={isLoading}
          slotProps={{
            wrapperProps: {
              className: 'self-center md:ml-auto shrink-0',
            },
          }}
          disabled={
            isLoading || isLoadingInvitations || !!legalRepresentativeInvitation
          }
          small
          onClick={redirectToMarketplaceOnboarding}
        >
          {legalRepresentativeInvitation
            ? 'Invitation sent'
            : 'Finish onboarding'}
        </Button>
        {legalRepresentativeInvitation && (
          <button
            className="text-sm underline"
            onClick={() => setIsDeleteInvitationModalOpen(true)}
          >
            Delete invitation
          </button>
        )}
      </div>
      <DeleteInvitationConfirmationModal
        open={isDeleteInvitationModalOpen}
        onClose={handleDeleteInvitationModalChoice}
      />
    </div>
  ) : null;
}

type DeleteInvitationConfirmationModalProps = Omit<
  ModalProps,
  'onClose' | 'children' | 'title'
> & {
  onClose: (shouldDelete: boolean) => Promise<void>;
};

function DeleteInvitationConfirmationModal(
  props: DeleteInvitationConfirmationModalProps,
) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    await props.onClose?.(true);
    setIsLoading(false);
  }, [props]);

  return (
    <Modal
      {...props}
      title="Delete invitation"
      onClose={() => {
        props.onClose?.(false);
      }}
      subTitle="Are you sure you want to delete this invitation?"
    >
      <div>
        <p>This action cannot be undone.</p>
        <div className="flex gap-2">
          <Button onClick={() => props.onClose?.(false)}>Cancel</Button>
          <Button primary onClick={handleDelete} isLoading={isLoading}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
