import containerQueries from '@tailwindcss/container-queries';

export default {
  content: ['./src/**/*.{js,ts,jsx,tsx}', './index.html'],
  theme: {
    extend: {
      animation: {
        loading: 'loading 1.5s infinite',
        appear: 'appear .5s ease-out',
      },
      keyframes: {
        loading: {
          '0%': { left: '-25%' },
          '100%': { left: '100%' },
        },
        appear: {
          '0%': { opacity: '0' },
          '100%': { opacity: '100%' },
        },
      },
      fontSize: {
        xxs: '0.625rem',
      },
      padding: {
        '1/2': '0.125rem',
      },
      fontFamily: {
        sans: ['Inter', 'sans-serif'],
      },
      colors: {
        'mydra-black': '#292929',
        'mydra-dark-purple': '#33244c',
        'mydra-gray': '#F7F7F7',
        'mydra-light-background': '#FBF5EF',
        'mydra-light-beige': '#FBF5EF',
        'mydra-light-blue': 'rgba(171, 186, 235, 0.40)',
        'mydra-light-green': 'rgba(185, 224, 208, 0.40)',
        'mydra-light-orange': '#F2BA94',
        'mydra-light-pink': '#F8AEAD',
        'mydra-light-purple': '#F3EFFF',
        'mydra-light-red': 'rgba(242, 186, 148, 0.40)',
        'mydra-lighter-orange-border': 'rgba(242, 186, 148, 0.60)',
        'mydra-lighter-orange': 'rgba(242, 186, 148, 0.20)',
        'mydra-medium-beige': '#E2D8D0',
        'mydra-medium-green': '#B9E0D0',
        'mydra-medium-gray': '#C2C2C2',
        'mydra-medium-purple': '#CFB9FE',
        'mydra-mouse': '#C2C2C2',
        'mydra-purple-light': 'rgba(230, 222, 255, 0.20)',
        'mydra-purple': '#895ad7',
        'mydra-violet': '#E6DEFF',
        'mydra-yellow': '#FCF2AD',
        'mydra-dark-red': '#d20819',
        'mydra-white': '#ffffff',
        'mydra-blue': '#ABBAEB',
        'gray-background': '#F7F7FA',
        'modal-backdrop': 'rgba(51, 36, 76, 0.40)',
        text: '#5E636F',
        titles: '#2A2A37',
      },
      backgroundImage: {
        'course-header':
          'linear-gradient(180deg, #F7F7FA 0%, rgba(230, 222, 255, 0.50) 100%);',
        'help-banner': 'url(/images/help-bg.jpg)',
      },
      boxShadow: {
        'user-menu': '2px 4px 20px 0px rgba(185, 185, 185, 0.25);',
        notification: '0px 3px 6px 0px rgba(94, 99, 111, 0.12);',
        tooltip: '0px 2px 4px 0px rgba(0, 0, 0, 0.12);',
      },
      toggle: [
        '0px 1px 3px rgba(0, 0, 0, 0.12)',
        '0px 1px 1px rgba(0, 0, 0, 0.14)',
        '0px 2px 1px rgba(0, 0, 0, 0.20)',
      ],
    },
  },
  plugins: [containerQueries],
};
