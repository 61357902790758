import { ApiErrorResponse } from '@/types/api';
import { ApiIndividualEducationProvider } from '@/types/education-provider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UseEducationProviderQueryOptions } from './useEducationProviderOptions';

export const useGetEducationProvider = (
  id: string,
  options?: Omit<
    UseQueryOptions<ApiIndividualEducationProvider, ApiErrorResponse>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<ApiIndividualEducationProvider, ApiErrorResponse>(
    UseEducationProviderQueryOptions(id, options),
  );
