import { FinishOnboardingBanner } from '@/modules/onboarding/components/FinishOnboardingBanner';
import { OnboardingStatusWidget } from '@/modules/onboarding/components/OnboardingStatusWidget';
import { SignTheContractBanner } from '@/modules/onboarding/components/SignTheContractBanner';
import { StripeBanner } from '@/modules/onboarding/components/StripeBanner';
import { NavBar } from '../components/NavBar';
import { SideBar } from '../components/SideBar';

type AdminLayoutProps = React.HTMLAttributes<HTMLDivElement> & {
  beforeLogo?: React.ReactNode;
  afterLogo?: React.ReactNode;
  beforeUserMenu?: React.ReactNode;
  hideSidebar?: boolean;
  hideUserMenu?: boolean;
  stickyNavBar?: boolean;
  hideBanners?: boolean;
  hideOnboardingStatusWidget?: boolean;
};

export const AdminLayout = function AdminLayout({
  beforeLogo,
  afterLogo,
  beforeUserMenu,
  hideSidebar,
  hideUserMenu,
  children,
  stickyNavBar,
  hideBanners,
  hideOnboardingStatusWidget,
  ...props
}: AdminLayoutProps) {
  return (
    <div
      {...props}
      className={`flex relative flex-col w-full overflow-hidden h-screen ${props.className ?? ''}`}
    >
      <NavBar
        beforeLogo={beforeLogo}
        afterLogo={afterLogo}
        beforeUserMenu={beforeUserMenu}
        showUserMenu={!hideUserMenu}
        className={`${stickyNavBar ? 'sticky top-0 w-full' : ''}`}
      />
      <div className="flex overflow-hidden flex-1">
        {!hideSidebar && (
          <SideBar className="flex flex-grow-0 flex-shrink-0 self-stretch basis-64" />
        )}

        <main className="overflow-auto w-full h-full @container">
          {!hideBanners && <StripeBanner className="mx-12 my-6" />}
          {!hideBanners && <SignTheContractBanner className="mx-12 my-6" />}
          {!hideBanners && <FinishOnboardingBanner className="mx-12 my-6" />}
          {children}
        </main>
      </div>
      {!hideOnboardingStatusWidget && (
        <OnboardingStatusWidget className="fixed left-2 bottom-12" />
      )}
    </div>
  );
};
