import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { IEducationProviderWithoutUserObjects } from '@/types/education-provider';
import { ListLandingPagesPageSearchParams } from '@/types/landing-page';
import { getRouteApi } from '@tanstack/react-router';
import { PaginationState } from '@tanstack/react-table';
import { ListLandingPagesTable } from '../components/ListLandingPagesTable/ListLandingPagesTable';
import { useLandingPages } from '../hooks/useLandingPages';

const routeApi = getRouteApi('/_admin/landing-pages/');

export function ListLandingPagesPage() {
  const userContext = useCurrentUserContext();
  const navigate = routeApi.useNavigate();
  const search = routeApi.useSearch() as ListLandingPagesPageSearchParams;

  const {
    isLoading,
    refetch,
    data: {
      data: landingPages = [],
      metadata: { total = 0, page = 1 } = {},
    } = {},
  } = useLandingPages({
    filters: {
      partner:
        (userContext!.educationProvider as IEducationProviderWithoutUserObjects)
          ?._id ?? '',
    },
    pagination: {
      page: search?.page ?? 1,
      limit: 10,
    },
  });

  const paginationState = {
    pageIndex: (page ?? 1) - 1 || 0,
    pageSize: 10,
  };

  const onPaginationChange = ({ pageIndex }: PaginationState) => {
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        page: pageIndex + 1,
      }),
    });
  };

  return (
    <div className="flex flex-col h-full">
      <header className="flex justify-between items-center px-12 py-5 border-b">
        <h1 className="text-2xl font-medium">Landing Pages</h1>
      </header>

      <div className="px-12 pt-8">
        <ListLandingPagesTable
          isLoading={isLoading}
          onMutation={refetch}
          onPaginationChange={updater => {
            return typeof updater === 'function'
              ? onPaginationChange(updater(paginationState))
              : onPaginationChange(updater);
          }}
          landingPages={landingPages}
          paginationState={paginationState}
          rowCount={total}
        />
      </div>
    </div>
  );
}
