import { Tag } from '@/modules/common/components/Tag';
import { PaymentStatus as PaymentStatusTypes } from '@/types/payment';

type PaymentStatusProps = {
  status: PaymentStatusTypes;
};

const statusBgColor: { [key in PaymentStatusTypes]: string } = {
  [PaymentStatusTypes.Authorized]: 'bg-mydra-purple-light',
  [PaymentStatusTypes.Pending]: 'bg-mydra-yellow',
  [PaymentStatusTypes.Failed]: 'bg-mydra-light-red',
  [PaymentStatusTypes.Paid]: 'bg-mydra-medium-green',
};

export function PaymentStatus({ status }: PaymentStatusProps) {
  return (
    <Tag
      className={statusBgColor[status] ?? ''}
      text={status.replace(/_/g, ' ').toLowerCase()}
    />
  );
}
