import { InvitationService } from '@/services/invitation';
import {
  ApiErrorResponse,
  ApiPaginationParams,
  ApiReactQueryOptions,
} from '@/types/api';
import {
  ApiPaginatedInvitations,
  IInvitationGetFilters,
} from '@/types/invitation';
import { useQuery } from '@tanstack/react-query';

export const getInvitationsQueryOptions = (
  filters?: IInvitationGetFilters,
  pagination?: ApiPaginationParams,
  options?: ApiReactQueryOptions<ApiPaginatedInvitations>,
) => ({
  queryKey: [InvitationService.PATH, filters, pagination],
  queryFn: async () => InvitationService.getInvitations(pagination),
  ...options,
});

export const useGetInvitations = (
  filters?: IInvitationGetFilters,
  pagination?: ApiPaginationParams,
  options?: ApiReactQueryOptions<ApiPaginatedInvitations>,
) => {
  return useQuery<ApiPaginatedInvitations, ApiErrorResponse>(
    getInvitationsQueryOptions(filters, pagination, options),
  );
};
