import { add } from 'date-fns';
import Cookies from 'js-cookie';

import { useCallback, useEffect, useState } from 'react';

const COOKIE_NAME = 'contract-info';
const COOKIE_LIFETIME_IN_MINUTES = 5;

const ESIO_CONTRACT_ID_KEY = 'esio_contract_id';
const ESIO_SIGNER_ID_KEY = 'esio_signer_id';
// No support for DocuSign flags yet

export function useContractSignatureTransientState() {
  const [contractInfo, setContractInfo] = useState<{
    envelopeId: string;
    signerId: string;
  } | null>(null);

  const isLoading = contractInfo === null;

  const clearContractTransientState = useCallback(() => {
    try {
      Cookies.remove(COOKIE_NAME);
    } finally {
      setContractInfo({
        envelopeId: '',
        signerId: '',
      });
    }
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const envelopeIdFromUrl = url.searchParams.get(ESIO_CONTRACT_ID_KEY);
    const signerIdFromUrl = url.searchParams.get(ESIO_SIGNER_ID_KEY);

    if (envelopeIdFromUrl && signerIdFromUrl) {
      const newContractInfo = {
        envelopeId: envelopeIdFromUrl,
        signerId: signerIdFromUrl,
      };

      setContractInfo(newContractInfo);

      Cookies.set(COOKIE_NAME, JSON.stringify(newContractInfo), {
        expires: add(new Date(), {
          minutes: COOKIE_LIFETIME_IN_MINUTES,
        }),
      });
    }
  }, [contractInfo]);

  useEffect(() => {
    if (contractInfo) {
      return;
    }

    try {
      const value = Cookies.get(COOKIE_NAME);
      setContractInfo(
        value
          ? JSON.parse(value.toString())
          : {
              envelopeId: '',
              signerId: '',
            },
      );
    } catch {
      setContractInfo({ envelopeId: '', signerId: '' });
    }
  }, [contractInfo]);

  return {
    contractInfo,
    isLoading,
    setContractInfo,
    clearContractTransientState,
  };
}
