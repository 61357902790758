import { useCurrentUserContext } from '@/modules/auth/hooks/useCurrentUserContext';
import { ContractSearchParams } from '@/routes/_admin/contract';
import { getRouteApi } from '@tanstack/react-router';
import { useEffect } from 'react';
import { ContractExplanationStep1 } from '../components/ContractExplanationStep1';
import { ContractExplanationStep2 } from '../components/ContractExplanationStep2';
import { ContractExplanationStep3 } from '../components/ContractExplanationStep3';
import { ContractExplanationStep4 } from '../components/ContractExplanationStep4';
import { ContractExplanationStep5 } from '../components/ContractExplanationStep5';

const routeApi = getRouteApi('/_admin/contract/');

const COMPONENT_BY_STEP = {
  1: ContractExplanationStep1,
  2: ContractExplanationStep2,
  3: ContractExplanationStep3,
  4: ContractExplanationStep4,
  5: ContractExplanationStep5,
};

export function SignContractPage() {
  const { useSearch } = routeApi;

  const {
    isLoading: isLoadingUser,
    currentUser,
    educationProvider,
  } = useCurrentUserContext() ?? {};

  const { step = 1 } = useSearch<ContractSearchParams>();

  const Component = COMPONENT_BY_STEP[step as keyof typeof COMPONENT_BY_STEP];

  const userIsLegalRepresentativeContact = !!(
    currentUser?.email &&
    educationProvider?.info?.contractRepresentativeContact &&
    educationProvider?.info?.contacts?.find(
      contact =>
        contact._id ===
          educationProvider?.info?.contractRepresentativeContact &&
        contact.email === currentUser.email,
    )
  );

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    const { onboardingState } = educationProvider ?? {};

    if (
      !onboardingState ||
      !onboardingState.hasLegalInformation ||
      !onboardingState.hasActivePaymentProvider ||
      !onboardingState.hasLegalRepresentativeContact ||
      !userIsLegalRepresentativeContact
    ) {
      throw new Error('Pre-requisites to sign the contract are not met.');
    }
  }, [isLoadingUser, educationProvider, userIsLegalRepresentativeContact]);

  return isLoadingUser ? null : <Component />;
}
